<template>
    <div class="page-body">
        <div class="table-body">
            <div class="table-header">
                <div class="handle-box">
                    <table width="100%">
                        <tr>
                            <td style="width: 80px;">
                                产品名称:
                            </td>
                            <td>
                                <el-input v-model="searchItem.materialName" size="small"
                                          placeholder="请输入产品名称"></el-input>
                            </td>
                            <td style="width: 80px">
                                适用机型:
                            </td>
                            <td>
                                <el-select v-model="searchItem.useType" size="small" style="width: 100%"
                                           placeholder="请选择适用机型">
                                    <el-option
                                            v-for="item in useTypeList"
                                            :key="item.wholeCode"
                                            :label="item.name"
                                            :value="item.wholeCode">
                                    </el-option>
                                </el-select>
                            </td>
                            <td style="width: 80px">
                                使用性质:
                            </td>
                            <td>
                                <el-select v-model="searchItem.useNature" size="small" style="width: 100%"
                                           placeholder="请选择使用性质">
                                    <el-option
                                            v-for="item in useNatureList"
                                            :key="item.wholeCode"
                                            :label="item.name"
                                            :value="item.wholeCode">
                                    </el-option>
                                </el-select>
                            </td>
                        </tr>
                        <tr>
                            <td style="width: 80px;padding-top: 10px">部件:</td>
                            <td>
                                <el-select v-model="searchItem.component" size="small"
                                           style="padding-top: 10px;width: 100%"
                                           placeholder="请选择部件">
                                    <el-option
                                            v-for="item in componentList"
                                            :key="item.wholeCode"
                                            :label="item.name"
                                            :value="item.wholeCode">
                                    </el-option>
                                </el-select>
                            </td>
                            <td></td>
                            <td></td>
                            <td style="width: 300px ;text-align: center;padding-top: 10px" colspan="2">
                                <el-button type="primary" icon="el-icon-search" size="small" @click="searchBtn">搜索
                                </el-button>
                                <el-button type="primary" icon="el-icon-refresh" size="small" style="margin-left: 20%"
                                           @click="reset">重置
                                </el-button>
                                <el-button type="primary" icon="el-icon-download" size="small" @click="exportDialogVisible = true;">导出为Excel文件</el-button>
                            </td>
                        </tr>
                    </table>
                </div>
            </div>
            <div class="table-info">
                <div class="table-button">
                    <div>
                        <el-table
                                :data="tableData"
                                :max-height="tableHeight"
                                style="width: 100%;height: 100%;"
                                :border="true"
                                :header-cell-style="{ background: '#EBEEF8',height: '50px', 'text-align':'center' }"
                                :cell-style="{padding:'0',height: lineHeight}">
                            <el-table-column type="index" width="64" label="排序" align="center"></el-table-column>
                            <el-table-column prop="materialCode" width="180" label="物料编码"
                                             align="center" show-overflow-tooltip></el-table-column>
                            <el-table-column prop="useTypeName" width="120" label="适用机型"
                                             align="center" show-overflow-tooltip></el-table-column>
                            <el-table-column prop="componentName" width="120" label="部件"
                                             align="center" show-overflow-tooltip></el-table-column>
                            <el-table-column prop="useNatureName" width="100" label="使用性质"
                                             align="center" show-overflow-tooltip></el-table-column>
                            <el-table-column prop="materialName" label="产品名称" align="center"
                                             show-overflow-tooltip></el-table-column>
                            <el-table-column prop="model" label="型号" width="180" align="center"
                                             show-overflow-tooltip></el-table-column>
                            <el-table-column prop="quantity" width="120" label="库房库存" align="center"
                                             show-overflow-tooltip></el-table-column>
                            <el-table-column prop="totalQuality" width="120" label="总库存"
                                             align="center" show-overflow-tooltip></el-table-column>
                            <el-table-column prop="safeQuantity" width="120" label="安全库存"
                                             align="center" show-overflow-tooltip></el-table-column>
                            <el-table-column prop="needQuantity" width="120" label="需求数量" align="center"
                                             show-overflow-tooltip>
                                <template slot-scope="scope">
                                    <span v-if="scope.row.needQuantity>0"
                                          style="color: red">{{scope.row.needQuantity}}</span>
                                    <span v-else>0</span>
                                </template>
                            </el-table-column>
                            <el-table-column width="150" label="操作" align="center">
                                <template slot-scope="scope">
                                    <i class="iconfont iconpandian" title="仓库盘点"
                                       @click="checkData(scope.row.mainId)"></i>
                                </template>
                            </el-table-column>
                        </el-table>
                    </div>
                </div>
                <div class="table-data">
                    <Pagination :pageSize="option.pageSize" :pageTotal="totalPage" :pageCurrent.sync="option.pageIndex"
                                :pageList="pageList" @handlePageChange="pageChange" @handleSizeChange="pageSizeChange"/>
                </div>
            </div>
        </div>
        <div class="dialog">
            <el-dialog center
                       :title="title"
                       :visible.sync="dialogVisible"
                       width="30%"
                       :close-on-click-modal="false"
                       :show-close="false">
                <el-form :model="checkForm" :rules="checkRules" ref="checkForm" label-width="100px"
                         class="demo-ruleForm">
                    <el-form-item size="small" label="领料库存" prop="checkQuantity">
                        <el-input type="tel" size="small"
                                  onKeypress="return (/[\d]/.test(String.fromCharCode(event.keyCode)))"
                                  v-model.number="checkForm.checkQuantity"></el-input>
                    </el-form-item>
                    <el-form-item size="small" label="库房库存" prop="checkTempQuantity">
                        <el-input type="tel" size="small"
                                  onKeypress="return (/[\d]/.test(String.fromCharCode(event.keyCode)))"
                                  v-model.number="checkForm.checkTempQuantity"></el-input>
                    </el-form-item>
                </el-form>
                <span class="dialog-footer" slot="footer">
                    <el-button type="formal" size="small" @click="closeDialog('checkForm')">取 消</el-button>
                    <el-button type="primary" size="small" @click="saveData('checkForm')" :loading="isLoad">保 存</el-button>
                </span>
            </el-dialog>

            <el-dialog
                title="库存明细表"
                :visible.sync="exportDialogVisible"
                width="53.8%"
                center
            >
                <el-table
                    :data="excelTableData"
                    max-height="400px"
                    size="small"
                    row-class-name="row"
                    cell-class-name="column"
                    :highlight-current-row="true"
                    fit
                >
                    <el-table-column
                    v-for="(item, index) in jsonFields"
                    :key="index"
                    :prop="item.prop"
                    :label="item.label"
                    show-overflow-tooltip
                    >
                    </el-table-column>
                </el-table>
                <download-excel
                        style="width: 80px;margin: 0px auto;margin-top: 10px;"
                        :data="json_data"
                        :fields="json_fields"
                        name="库存明细表"
                        >
                    <el-button
                        type="primary"
                        size="small"
                        @click="exportDialogVisible = false"
                        >导出数据
                    </el-button>
                </download-excel>
            </el-dialog>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                exportDialogVisible:false,
                excelTableData:[],
                json_data:[],
                json_fields: {},
                jsonFields: [
                    { label: "物料编码", prop: "materialCode" },
                    { label: "适用机型", prop: "useTypeName" },
                    { label: "部件", prop: "componentName" },
                    { label: "物料名称", prop: "materialName" },
                    { label: "型号", prop: "model" },
                    { label: "单位", prop: "unitName" },
                    { label: "库房库存", prop: "quantity" },
                    { label: "总库存", prop: "totalQuality" },
                    { label: "单价(元)", prop: "buyPrice" },
                    { label: "总价(元)", prop: "buyCount" },
                ],
                pageList: [15, 20, 30, 50],
                tableHeight: '',
                tableData: [],
                option: {
                    pageSize: 15,
                    pageIndex: 11,
                },
                totalPage: 10,
                checkQuantity: 0,
                checkTempQuantity: 0,
                totalQuantity: 0,
                dialogVisible: false,
                isLoad: false,
                searchItem: {
                    useNature: '',
                    component: '',
                    materialName: '',
                    useType: '',
                    pageIndex: 1,
                    pageSize: 15,
                },
                checkForm: {
                    mainId: '',
                    checkQuantity: 0,
                    checkTempQuantity: 0
                },
                checkRules: {
                    checkQuantity: [
                        {required: true, message: '请输入库房库存'}
                    ],
                    checkTempQuantity: [
                        {required: true, message: '请输入领料库存'}
                    ]
                },
                lineHeight: '',
                componentList: [],
                useTypeList: [],
                useNatureList: [],
                title: '',
                currentPage: 1,
                pageSize: 15,
            };
        },
        methods: {
            getDictionary() {
                this.$api.getDictionaryData().then(res => {
                    if (res.code == 200) {
                        this.useTypeList = res.data.useTypeList;
                        this.componentList = res.data.componentList;
                        this.useNatureList = res.data.useNatureList;
                    }
                })
            },
            pageChange(option) {
                if(option != "refresh"){
                    this.option.pageIndex = option;
                    this.searchItem.pageIndex = option;
                }
                this.tableData = [];
                // this.option.pageIndex = option;
                // this.searchItem.pageIndex = option;
                this.pageCurrent = option;
                this.$api.getMainMaterial(this.searchItem).then(res => {
                    if (res.code == 200) {
                        for (var item of res.data.content) {
                            var tableItem = {};
                            tableItem = item;
                            tableItem.totalQuality = item.quantity + item.tempQuantity
                            tableItem.needQuantity = item.safeQuantity - item.totalQuality
                            tableItem.buyCount = (tableItem.buyCount/100).toFixed(2)
                            tableItem.buyPrice = (tableItem.buyPrice/100).toFixed(2)
                            this.tableData.push(tableItem);
                        }
                        this.totalPage = res.data.totalElements;
                    }
                })
                this.excelTableData = this.tableData
                this.json_data = this.tableData
            },
            pageSizeChange(val) {
                this.searchItem.pageSize = val;
                this.$api.getMainMaterial(this.searchItem).then(res => {
                    if (res.code == 200) {
                        for (var item of res.data.content) {
                            var tableItem = {};
                            tableItem = item;
                            tableItem.totalQuality = item.quantity + item.tempQuantity
                            tableItem.needQuantity = item.safeQuantity - item.totalQuality
                            tableItem.buyCount = (tableItem.buyCount/100).toFixed(2)
                            tableItem.buyPrice = (tableItem.buyPrice/100).toFixed(2)
                            this.tableData.push(tableItem);
                        }
                        this.totalPage = res.data.totalElements;
                    }
                })
                 this.excelTableData = this.tableData
                 this.json_data = this.tableData
            },
            reset() {
                this.searchItem = {
                    useNature: '',
                    component: '',
                    materialName: '',
                    useType: '',
                    pageIndex: 1,
                    pageSize: 15,
                }
                this.pageChange(1);
            },
            closeDialog(formName) {
                this.$refs[formName].resetFields();
                this.dialogVisible = false;
            },
            saveData(formName) {
                this.isLoad = true;
                this.$refs[formName].validate((valid) => {
                    if (this.checkForm.checkQuantity < 0) {
                        this.$message.error('输入的盘点库房库存不能小于0');
                        this.isLoad = false;
                        return;
                    }
                    if (this.checkForm.checkTempQuantity < 0) {
                        this.$message.error('输入的盘点领料库存不能小于0');
                        this.isLoad = false;
                        return;
                    }
                    if (this.checkForm.checkTempQuantity + this.checkTempQuantity >= this.totalQuantity) {
                        this.$message.error('总库存大于已录入的总库存，请进行入库操作');
                        this.isLoad = false;
                        return;
                    }
                    this.$api.saveCheckData(this.checkForm).then(res => {
                        if (res.code == 200){
                            this.pageChange("refresh");
                            this.dialogVisible = false;
                            this.$message.success(res.message)
                            this.isLoad = false;
                        }else {
                            this.$message.error(res.message)
                            this.isLoad = false;
                        }
                    })
                });
            },
            checkData(id) {
                this.$api.getDataById({id: id}).then(res => {
                    if (res.code == 200) {
                        this.checkForm = {
                            checkQuantity: res.data.quantity,
                            checkTempQuantity: res.data.tempQuantity,
                        }
                        this.dialogVisible = true;
                        this.title = '编辑盘点数据'
                        this.checkQuantity = res.data.quantity;
                        this.checkTempQuantity = res.data.tempQuantity;
                        this.totalQuantity = res.data.tempQuantity + res.data.quantity;
                    }
                })
            },
            searchBtn() {
                this.pageChange(1);
            },
        },
        created() {
            this.getDictionary();
            this.tableHeight = (window.innerHeight) * 0.71
            this.pageChange(1);
            this.jsonFields.map((item, i) => {
                this.json_fields[item.label] = item.prop;
            });
            if (window.screen.height > 900) {
                this.lineHeight = window.screen.height * 0.038 + 'px';
            } else {
                this.lineHeight = window.screen.height * 0.033 + 'px';
            }
        }
    };
</script>
<style lang="scss" scoped>
    .iconfont {
        font-family: "iconfont" !important;
        font-size: 24px;
        font-style: normal;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        margin-top: 3px;
        display: block;
        cursor: pointer;
    }

    .page-body {
        background-color: #FFFFFF;
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
    }

    .table-body {
        display: flex;
        flex-direction: column;
        height: 100%;
    }

    .table-header {
        height: 100px;
        margin-top: 0.5%;
        background-color: #FFFFFF;
    }

    .handle-box {
        padding: 10px;
    }

    .table-info {
        height: 94%;
        margin-top: 5px;
        background-color: #FFFFFF;
        display: flex;
        flex-direction: column;
    }

    .table-button {
        flex: 1;
        margin: 0 1%;
    }

    .table-data {
        height: 6%;
        display: flex;
        justify-content: center;
        align-items: center;
    }


    td {
        text-align: right;
        white-space: nowrap; /*控制单行显示*/
        overflow: hidden; /*超出隐藏*/
        text-overflow: ellipsis; /*隐藏的字符用省略号表示*/
    }
</style>